.tutorial-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  inset: 0;

  opacity: 0;

  pointer-events: none!important;

  .tutorial-card {
    opacity: 0;
  }

  &.is-gone {
    opacity: 0;
    transition: var(--off-transition-long) 1s;
    .tutorial-card {
      opacity: 0;
    }
  }

  &.is-active {
    opacity: 1;
    transition: var(--on-transition-long) 1s;
    transition-delay: 5s;
    .tutorial-card {
      opacity: 1;
    }
  }
}

.tutorial-card{
  width: 70%;

  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;

  z-index: 500;

  border-radius: 1.25rem;
  background: rgba(133, 200, 239, 0.507);
  backdrop-filter: blur(35px);

  .explanation {
    font-size: 18px;
    text-align: center;
    padding: 10px 10px 0 10px;
  }
}




//=======================================================================//
// media queries=========================================================//
//=======================================================================//
@media (min-width: 768px) {
  .tutorial-card{
    width: 40%;
  }
}

@media (min-width: 1440px) {
  .tutorial-card{
    width: 20%;
    .explanation {
      font-size: 35px;
      text-align: center;
      padding: 15px 40px 0 40px;
    }
  }
  .tutorial-container {
    padding-top: 20px;
  }
}

@media (hover: hover) {
  #bodymovinTouch {
    display: none;
  }
}

@media (hover: none) {
  #bodymovinDesktop {
    display: none;
  }
}