:root {
  --red: #e30613;
  --red-hover: #ff2433;
  --red-active: #96010b;
  --white: #fff;
  --white-transparent: #ffffff80;
  --black: #000;
  --glass-background: #4d728d1a;
  --off-transition: .2s ease-in;
  --on-transition: .2s ease-out;
  --off-transition-long: .5s ease-in;
  --on-transition-long: .5s ease-out;
  --nav-padding: 20px;
  --blue: #99d5ff;
  --blue-transparent: #99d5ffac;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 20px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  color: var(--white);
  font-family: Barlow Condensed;
  font-size: 1rem;
  line-height: 1.25;
  overflow: hidden;
}

a {
  color: inherit;
}

img, video {
  vertical-align: middle;
  block-size: auto;
  max-inline-size: 100%;
}

h1, h2.h3, h4, h5, h6 {
  font-size: inherit;
}

.description-container, .word-found-animation {
  -webkit-user-select: none;
  user-select: none;
}

body {
  background: var(--blue);
  opacity: 1;
  transition: opacity .3s var(--off-transition);
}

body.resizing {
  opacity: 0;
  transition: opacity .3s var(--off-transition);
}

.logo img {
  width: 35%;
  margin-top: 3px;
  margin-left: 10px;
}

@media (width >= 768px) {
  .logo img {
    width: 25%;
    margin-top: 20px;
    margin-left: 20px;
  }
}

@media (width >= 1200px) {
  .logo img {
    width: 15%;
  }
}

@media (width >= 1440px) {
  .logo img {
    width: 10%;
  }
}

@media (width >= 2000px) {
  .logo img {
    width: 6%;
  }
}

canvas {
  z-index: -1;
  pointer-events: all;
  filter: saturate() contrast(110%);
  position: fixed;
  inset: 0;
}

.description-container {
  z-index: 100;
  opacity: 0;
  width: 35%;
  font-size: 10px;
  position: absolute;
  top: 3px;
  right: 10px;
  overflow: hidden;
}

.description-container.is-active {
  opacity: 1;
  transition: var(--on-transition-long) 1s;
}

.title {
  text-align: right;
  text-transform: uppercase;
  color: var(--black);
  margin: 0 5px 3px 0;
  font-size: 15px;
}

.description {
  background: var(--glass-background);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  margin-bottom: 5px;
  padding: 1.5px 12px;
  transition: all 1s;
  display: block;
}

.description h2, .description h3 {
  filter: blur(2px);
}

.description h2 {
  text-transform: uppercase;
  transition: var(--off-transition);
  font-weight: normal;
}

.description h3 {
  transition: var(--off-transition);
  font-weight: normal;
  line-height: 90%;
  display: none;
}

.description.is-active {
  background: var(--red);
  filter: blur();
  padding: 8px 12px;
}

.description.is-active h2 {
  text-shadow: none;
  font-weight: bold;
}

.description.is-active h3 {
  text-shadow: none;
  margin-top: 3px;
  display: block;
}

.description.is-found h2, .description.is-found h3 {
  filter: blur() !important;
}

@media (width >= 768px) {
  .description-container {
    width: 25%;
    font-size: 14px;
    top: 20px;
    right: 20px;
  }

  .description h3 {
    padding-bottom: 3px;
    font-size: 16px;
    line-height: 90%;
    display: none;
  }

  .description h2, .description h3 {
    filter: blur(3px);
  }

  .title {
    font-size: 20px;
  }
}

@media (width >= 1200px) {
  .description-container {
    width: 15%;
    font-size: 20px;
  }

  .description h3 {
    font-size: 20px;
    line-height: 90%;
  }

  .description h2, .description h3 {
    filter: blur(3.5px);
  }
}

@media (width >= 2200px) {
  .description-container {
    width: 10%;
    font-size: 25px;
  }

  .description h2, .description h3 {
    filter: blur(4.5px);
  }

  .description h3 {
    font-size: 25px;
  }

  .title {
    font-size: 30px;
  }
}

.description:hover {
  cursor: pointer;
}

.description:hover h2 {
  filter: blur() !important;
}

.description-unblur {
  filter: blur(1.5px) !important;
}

.welcome-page {
  text-align: center;
  z-index: 1000;
  background: linear-gradient(79deg, #5c8dc6 0%, #97c4f7 19% 75%, #5c8dc6 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  position: absolute;
  inset: 0;
}

.welcome-page.is-gone {
  opacity: 0;
  transition: var(--off-transition) .5s;
  pointer-events: none !important;
}

.welcome-page.is-gone * {
  pointer-events: none !important;
}

.welcome-page img {
  transition: var(--off-transition);
  z-index: 2;
  width: 50%;
}

.welcome-page img.is-gone {
  opacity: 0;
  pointer-events: none !important;
}

.start-container {
  opacity: 0;
  transition: var(--off-transition) .2s;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.start-container.is-active {
  opacity: 1;
  pointer-events: all;
  transition: var(--on-transition) .2s;
}

.explanation {
  color: var(--white);
  margin-bottom: 20px;
  font-weight: 500;
}

.start-button {
  background: var(--glass-background);
  color: var(--white);
  text-transform: uppercase;
  cursor: pointer;
  transition: var(--off-transition);
  border-radius: 10px;
  padding: 12px 30px 14px;
  line-height: 25px;
}

.start-button:active {
  background: var(--red-active);
  transition: transform var(--off-transition);
  transform: scale(.8);
}

.start-button:hover {
  background: var(--red-hover);
  transition: var(--off-transition);
  font-size: 26px;
}

.logo-background {
  background: var(--white);
  width: 100%;
  height: 100%;
  position: absolute;
}

.logo-background.is-gone {
  opacity: 0;
  pointer-events: none !important;
}

.mobile-disclaimer {
  font-size: 10px;
  font-weight: 100;
  position: fixed;
  bottom: 10px;
}

@media (width >= 768px) {
  .explanation {
    padding: 0 10%;
    font-size: 35px;
  }
}

@media (width >= 1440px) {
  .explanation {
    padding: 0 10%;
    font-size: 40px;
  }
}

@media (hover: hover) {
  .mobile-disclaimer {
    display: none;
  }
}

.tutorial-container {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  pointer-events: none !important;
}

.tutorial-container .tutorial-card {
  opacity: 0;
}

.tutorial-container.is-gone {
  opacity: 0;
  transition: var(--off-transition-long) 1s;
}

.tutorial-container.is-gone .tutorial-card {
  opacity: 0;
}

.tutorial-container.is-active {
  opacity: 1;
  transition: var(--on-transition-long) 1s;
  transition-delay: 5s;
}

.tutorial-container.is-active .tutorial-card {
  opacity: 1;
}

.tutorial-card {
  text-transform: uppercase;
  z-index: 500;
  backdrop-filter: blur(35px);
  background: #85c8ef81;
  border-radius: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  padding-bottom: 10px;
  display: flex;
}

.tutorial-card .explanation {
  text-align: center;
  padding: 10px 10px 0;
  font-size: 18px;
}

@media (width >= 768px) {
  .tutorial-card {
    width: 40%;
  }
}

@media (width >= 1440px) {
  .tutorial-card {
    width: 20%;
  }

  .tutorial-card .explanation {
    text-align: center;
    padding: 15px 40px 0;
    font-size: 35px;
  }

  .tutorial-container {
    padding-top: 20px;
  }
}

@media (hover: hover) {
  #bodymovinTouch {
    display: none;
  }
}

@media (hover: none) {
  #bodymovinDesktop {
    display: none;
  }
}

.word-found-animation {
  color: var(--white);
  pointer-events: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  transition: all .5s;
  display: flex;
  position: absolute;
  inset: 0;
}

.word-found-animation .animation-description {
  text-shadow: 6px 6px 20px #24242480;
  font-size: 20px !important;
  font-weight: normal !important;
}

.word-found-animation.is-active {
  box-shadow: inset 0 4px 120.8px #fff, inset 2px 7px 250px #fff;
}

.animation-box {
  opacity: 0;
  backdrop-filter: blur();
  border: 1px solid red;
  transition: -webkit-backdrop-filter .5s, backdrop-filter .5s;
}

.animation-box.animation-active {
  backdrop-filter: blur(4px);
  z-index: 100000;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  transition: -webkit-backdrop-filter .5s, backdrop-filter .5s;
  animation: 5s .6s TextAnimation;
  display: flex;
}

@keyframes TextAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  10% {
    opacity: 1;
    transform: scale(1.1);
  }

  90% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
  }
}

@media (width >= 768px) {
  .animation-box.animation-active {
    padding-left: 25%;
  }

  @keyframes TextAnimation {
    0% {
      opacity: 0;
      transform: scale(1);
    }

    10% {
      opacity: 1;
      transform: scale(1.7);
    }

    90% {
      opacity: 1;
      transform: scale(1.7);
    }

    100% {
      opacity: 0;
    }
  }
}

@media (width >= 1000px) {
  .animation-box.animation-active {
    padding-left: 33%;
  }

  @keyframes TextAnimation {
    0% {
      opacity: 0;
      transform: scale(1);
    }

    10% {
      opacity: 1;
      transform: scale(2.5);
    }

    90% {
      opacity: 1;
      transform: scale(2.5);
    }

    100% {
      opacity: 0;
    }
  }
}

.win-screen {
  z-index: 9999;
  text-align: center;
  color: var(--white);
  opacity: 0;
  pointer-events: none;
  background: linear-gradient(79deg, #5c8dc6 0%, #97c4f7 19% 75%, #5c8dc6 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 5vh;
  font-weight: 500;
  transition: opacity 1s;
  display: flex;
  position: fixed;
  inset: 0;
}

.win-screen.is-active {
  opacity: 1;
  pointer-events: all;
}

.win-screen p {
  width: 50%;
  margin-bottom: 20px;
  font-size: clamp(20px, 3vw, 35px);
}

.win-screen p:nth-child(3) {
  margin-bottom: 0;
}

.win-message {
  font-size: clamp(30px, 7vw, 50px);
  font-weight: 700;
}

.replay-button {
  background: var(--glass-background);
  color: var(--white);
  text-transform: uppercase;
  cursor: pointer;
  transition: var(--off-transition);
  border-radius: 10px;
  padding: 12px 30px 14px;
  font-size: 26px;
  line-height: 25px;
}

.replay-button:active {
  background: var(--red-active);
  transition: transform var(--off-transition);
  transform: scale(.8);
}

.replay-button:hover {
  background: var(--red-hover);
  transition: var(--off-transition);
}

#newLocation .timer {
  font-size: 4vh;
}

#newLocation .star-container {
  width: 120px;
  margin: 30px auto;
}

@media (width >= 768px) {
  #newLocation .star-container {
    width: 160px;
  }
}

@media (width >= 1200px) {
  #newLocation .star-container {
    width: 200px;
  }
}

.score-counter {
  opacity: 0;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  width: 35%;
  font-size: 25px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.score-counter.is-active {
  opacity: 1;
  transition: var(--on-transition-long) 1s;
}

.text-counter {
  color: var(--red);
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.star-container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.star {
  width: 30px;
}

.is-gone {
  opacity: .3;
}

@media (width >= 768px) {
  .score-counter {
    width: 25%;
    font-size: 25px;
  }

  .star {
    width: 40px;
  }
}

@media (width >= 1200px) {
  .score-counter {
    width: 15%;
    font-size: 30px;
  }

  .star {
    width: 45px;
    margin-bottom: 10px;
  }
}

@media (width >= 2200px) {
  .score-counter {
    width: 10%;
    padding-bottom: 20px;
    padding-right: 10px;
    font-size: 45px;
  }

  .star {
    width: px;
    margin-bottom: 10px;
  }
}

@font-face {
  font-family: Barlow Condensed;
  src: url("BarlowCondensed-SemiBold.f4d796f9.woff2") format("woff2"), url("BarlowCondensed-SemiBold.4103a054.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Barlow Condensed;
  src: url("BarlowCondensed-Bold.bd585f87.woff2") format("woff2"), url("BarlowCondensed-Bold.82049091.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Barlow Condensed;
  src: url("BarlowCondensed-Regular.5630f19e.woff2") format("woff2"), url("BarlowCondensed-Regular.068d98ab.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Barlow Condensed;
  src: url("BarlowCondensed-Medium.fa98922f.woff2") format("woff2"), url("BarlowCondensed-Medium.5ef067f9.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=index.b6f23732.css.map */
