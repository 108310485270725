@import "base";
@import "layout";
@import "modules/three";
@import "modules/description";
@import "modules/welcome-page";
@import "modules/tutorial";
@import "modules/word-found-animation";
@import "modules/win-screen";
@import "modules/score-counter";



@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-SemiBold.woff2') format('woff2'),
      url('../fonts/BarlowCondensed-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Bold.woff2') format('woff2'),
      url('../fonts/BarlowCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Regular.woff2') format('woff2'),
      url('../fonts/BarlowCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Medium.woff2') format('woff2'),
      url('../fonts/BarlowCondensed-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}