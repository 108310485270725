/* ====================================
// css variables
// ==================================== */

:root {
  --red: #e30613;
  --red-hover: #ff2433;
  --red-active: #96010b;
  --white: #fff;
  --white-transparent: rgba(255, 255, 255, 0.5);
  --black: #000;
  --glass-background: rgba(77, 114, 141, 0.1);
  --off-transition: 0.2s ease-in;
  --on-transition: 0.2s ease-out;
  --off-transition-long: 0.5s ease-in;
  --on-transition-long: 0.5s ease-out;
  --nav-padding: 20px;
  --blue: #99d5ff;
  --blue-transparent: #99d5ffac;
}

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 20px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow: hidden;

  font-size: 1rem;
  line-height: 1.25;
  font-family: "Barlow Condensed";

  color: var(--white);
}

a {
  color: inherit;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}
h1,
h2.h3,
h4,
h5,
h6 {
  font-size: inherit;
}
/* ====================================
// base rules
// ==================================== */

.description-container,
.word-found-animation {
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}

body {
  background: var(--blue);
  opacity: 1;
  transition: opacity 0.3s var(--off-transition);

  &.resizing {
    opacity: 0;
    transition: opacity 0.3s var(--off-transition);
  }
}

.logo img {
  width: 35%;
  margin-left: 10px;
  margin-top: 3px;
}

@media (min-width: 768px) {
  .logo img {
    margin-top: 20px;
    width: 25%;
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .logo img {
    width: 15%;
  }
}

@media (min-width: 1440px) {
  .logo img {
    width: 10%;
  }
}

@media (min-width: 2000px) {
  .logo img {
    width: 6%;
  }
}
