.word-found-animation {
  position: absolute;
  inset: 0;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  color: var(--white);
  font-size: 30px;
  font-weight: bold;

  pointer-events: none;

  transition: 0.5s;

  .animation-description {
    font-size: 20px!important;
    font-weight: normal!important;
    text-shadow: 6px 6px 20px rgba(36, 36, 36, 0.5);
  }

  &.is-active {
    box-shadow: 0px 4px 120.8px 0px #fff inset, 2px 7px 250px 0px #fff inset;
  }

}

.animation-box {
  opacity: 0;
  backdrop-filter: blur(0px);
  transition: backdrop-filter 0.5s;
  border: solid 1px red;

  &.animation-active {
    animation: TextAnimation 5s ;
    animation-delay: 0.6s;
    backdrop-filter: blur(4px);

    height: 100%;
    width: 100%;
    padding-left: 10%;

    transition: backdrop-filter 0.5s;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    z-index: 100000;
  }
}

@keyframes TextAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  90% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
  }
}




//=======================================================================//
// media queries=========================================================//
//=======================================================================//
@media (min-width: 768px) {

  .animation-box {
    &.animation-active {
      padding-left: 25%;
    }
  }

  @keyframes TextAnimation {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    10% {
      opacity: 1;
      transform: scale(1.7);
    }
    90% {
      opacity: 1;
      transform: scale(1.7);
    }
    100% {
      opacity: 0;
    }
  }
}

@media (min-width: 1000px) {
  .animation-box {
    &.animation-active {
      padding-left: 33%;
    }
  }

    @keyframes TextAnimation {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    10% {
      opacity: 1;
      transform: scale(2.5);
    }
    90% {
      opacity: 1;
      transform: scale(2.5);
    }
    100% {
      opacity: 0;
    }
  }
}
