.win-screen {
  position: fixed;
  inset: 0 0 0 0;

  z-index: 9999;

  background: linear-gradient(79deg, rgb(92, 141, 198) 0%, rgb(151, 196, 247) 19%, rgb(151, 196, 247) 75%, rgb(92, 141, 198) 100%);

  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--white);
  font-size: 5vh;
  font-weight: 500;

  opacity: 0;
  transition: opacity 1s;

  pointer-events: none;
 
  &.is-active {
    opacity: 1;

    pointer-events: all;
  }
 
  p {
    margin-bottom: 20px;
    width: 50%;

    font-size: clamp(20px, 3vw, 35px);

    &:nth-child(3) {
      margin-bottom: 0px;
    }
  }
}

.win-message {
  font-size: clamp(30px, 7vw, 50px);
  font-weight: 700;
}

.replay-button {
  padding: 12px 30px 14px 30px;

  border-radius: 10px;
  background: var(--glass-background);
  
  color: var(--white);
  line-height: 25px;
  text-transform: uppercase;
  font-size: 26px;
 
  cursor: pointer;
 
  transition: var(--off-transition);
 
  &:active {
    background: var(--red-active);

    transform: scale(0.8);
    transition: transform var(--off-transition);
  }
 
  &:hover {
    background: var(--red-hover);

    transition: var(--off-transition);
  }
}
 
#newLocation .timer {
  font-size: 4vh;
}
 
#newLocation .star-container {
  width: 120px;
  margin: 30px auto;
}



@media (min-width:768px) {
  #newLocation .star-container {
    width: 160px;
  }
}

@media (min-width:1200px) {
  #newLocation .star-container {
    width: 200px;
  }
}

